<template>
  <div class="select-user">
    <h5 class="mb-4">{{ $t("select-client") }}</h5>
    <p>
      Please select the owner user of this book. Enter part of the name of the
      user to search
    </p>
    <FormGenerator
      :elements="elements"
      :handleUpdate="handleUpdate"
      :data="book"
      inline
      v-bind="$attrs"
      :shouldCheckValidity="validate"
    />
  </div>
</template>

<script>
import FormGenerator from "@/components/form-generator";

export default {
  name: "select-user",
  components: {
    FormGenerator
  },
  props: {
    book: {
      type: Object,
      required: true
    },
    handleUpdate: {
      type: Function,
      required: true
    },
    validate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      users: [],
      loading: false
    };
  },
  computed: {
    elements() {
      return [
        {
          key: "user",
          label: "client",
          type: "multiselect",
          placeholder: "Enter some text to search for Users",
          attrs: {
            loading: this.loading,
            multiple: false,
            options: (() => {
              let users = this.users;
              if (this.book.user) {
                users = [this.book.user || {}].concat(this.users);
              }

              return users;
            })(),
            trackBy: "id",
            label: "name"
          },
          events: {
            "search-change": this.search
          }
        }
      ];
    }
  },
  methods: {
    async search(q) {
      if (q) {
        this.loading = true;
        try {
          const res = await this.$store.dispatch("users/search", {
            search: q
          });
          this.users = res.data.data;
        } catch (err) {
          console.error(err);
        }
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
